<template>
  <section>
    <Toast />
    <div class="w-full box p-4 mt-2">
      <div class="w-full flex justify-between">
        <div class="flex items-center gap-2">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listas de precio</p>
        </div>
        <div class="flex gap-2 items-center">
          <Button label="Crear lista de precio" severity="success" @click="displayListaPrecio = true" icon="pi pi-upload" />
          <Button label="Descargar plantilla" severity="secondary" @click="listaPrecioStore.descargarPlantillaListaPrecio()" icon="pi pi-download" />
        </div>
      </div>
      <div>
        <DataTable
          v-model:expandedRows="expandedRows"
          :value="listaPrecioStore._paginado_lista_precio.rows"
          class="mt-4"
          responsiveLayout="scroll"
          tableStyle="min-width: 50rem"
          dataKey="ListPriceCL"
          showGridlines
          @rowExpand="onRowGroupExpand($event)"
        >
        <template #empty> No se encontraron listas de precio </template>
          <Column expander :style="{'width': '5rem'}" />
          <Column field="Nombre" header="Nombre">
            <template #body="slotProps">
              <div class="w-full flex justify-between items-center">
                <div>
                  <div v-if="!slotProps.data.editar_lista">
                    <p>
                      {{ slotProps.data.Nombre }}
                    </p>
                  </div>
                  <InputText v-else type="text" v-model="slotProps.data.nombre_editar" />
                </div>
                <div class="flex gap-2">
                  <Button icon="pi pi-pencil" v-if="!slotProps.data.editar_lista" @click="slotProps.data.editar_lista = true" rounded outlined v-tooltip.left="'Editar'" aria-label="Editar" />
                  <Button icon="pi pi-save" v-if="slotProps.data.editar_lista" @click="editarNombreListaPrecio(slotProps.data)" rounded v-tooltip.left="'Guardar'" aria-label="Guardar" />
                  <Button icon="pi pi-times" severity="danger" v-if="slotProps.data.editar_lista" @click="cancelarGuardarLista(slotProps.data)" rounded v-tooltip.left="'Cancelar'" aria-label="Cancelar" />
                  <Button icon="pi pi-plus" v-if="!slotProps.data.editar_lista" @click="abrirModalAgregarMedicamento(slotProps.data)" severity="success" rounded outlined v-tooltip.left="'Agregar medicamento'" aria-label="Agregar medicamento" />
                  <Button icon="pi pi-file-excel" severity="help" @click="descargarListaPrecio(slotProps.data)" rounded outlined v-tooltip.left="'Descargar lista de precio'" aria-label="Descargar" />
                  <Button icon="pi pi-upload" severity="primary" @click="displayModalActualizarMasivo = true, listaSeleccionadaActualizar = slotProps.data"  rounded outlined v-tooltip.left="'Actualizar medicamentos masivo'" aria-label="Subir medicantos masivo" />
                </div>
              </div>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div>
              <div class="w-full mb-2">
                <InputText size="small" v-model="filtersInside.ItemCode" @input="paginarInterno(slotProps.data.ListPriceCL)" placeholder="Buscar ItemCode" />
              </div>
              <DataTable showGridlines class="p-datatable-sm text-xs" :value="listaPrecioStore._paginado_mds_precios_lista_precio.rows" tableStyle="min-width: 50rem">
                  <Column field="ItemCode" header="Medicamento"></Column>
                  <Column field="Price" header="Precio">
                    <template #body="slotProps">
                      <p v-if="!slotProps.data.editar">{{ $h.formatCurrency(parseInt(slotProps.data.Price), 2) }}</p>
                      <InputNumber v-if="slotProps.data.editar" v-model="slotProps.data.precio_editar" inputId="withoutgrouping" />
                    </template>
                  </Column>
                  <Column field="createdAt" header="Fecha ingreso">
                    <template #body="slotProps">
                      <p  class="font-bold">
                        {{ slotProps.data.createdAt ? dayjs(slotProps.data.createdAt).format('YYYY-MM-DD hh:mm A') : 'NO REGISTRA' }}
                      </p>
                    </template>
                  </Column>
                  <Column field="updatedAt" header="Fecha actualización">
                    <template #body="slotProps">
                      <p  class="font-bold">
                        {{ slotProps.data.updatedAt ? dayjs(slotProps.data.updatedAt).format('YYYY-MM-DD hh:mm A') : 'NO REGISTRA' }}
                      </p>
                    </template>
                  </Column>
                  <Column field="" header="Estado">
                    <template #body="slotProps">
                      <p :class="slotProps.data.deletedAt ? 'text-red-600' : 'text-green-600'" class="font-bold">
                        {{ slotProps.data.deletedAt ? ('Inactivado el día ' + slotProps.data.deletedAt) : 'Activo' }}
                      </p>
                    </template>
                  </Column>
                  <Column field="" header="Acciones" class="w-20">
                    <template #body="slotProps">
                      <div class="w-full flex gap-2 text-center">
                        <Button icon="pi pi-pencil" v-if="!slotProps.data.editar" @click="slotProps.data.editar = true" rounded text severity="info" />
                        <Button icon="pi pi-save" v-if="slotProps.data.editar" @click="actualizarPrecio(slotProps.data)" rounded />
                        <Button v-if="slotProps.data.editar" icon="pi pi-times" severity="danger" @click="slotProps.data.editar = false" rounded aria-label="Filter" />
                      </div>
                    </template>
                  </Column>
              </DataTable>
              <Paginator @page="onPageInside($event)" :rows="limit" :totalRecords="listaPrecioStore._paginado_mds_precios_lista_precio.count" :rowsPerPageOptions="[10, 20, 30]"></Paginator>
            </div>
          </template>
        </DataTable>
        <Paginator @page="onPage($event)" :rows="limit" :totalRecords="listaPrecioStore._paginado_lista_precio.count" :rowsPerPageOptions="[10, 20, 30]"></Paginator>
      </div>
    </div>
    <Dialog v-model:visible="displayListaPrecio" modal header="Crear lista precio" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="w-full">
        <div class="w-full my-2">
          <label for="nombreLista" class="text-xs mb-1 text-gray-600 italic">Nombre de la lista de precios</label>
          <InputText class="w-full" id="nombreLista" type="text" v-model="listaEnviar.nombre" />
        </div>
        <div>
          <div class="w-full bg-blue-300 p-2 rounded-md text-blue-600 flex justify-between font-bold" v-if="listaEnviar.nombre_archivo">
            <p>
              {{ listaEnviar.nombre_archivo }}
            </p>
            <button @click="borrarArchivo"><i class="pi pi-times"></i></button>
          </div>
          <FileUpload
            v-else
            mode="basic"
            class="w-full"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :maxFileSize="1000000"
            chooseLabel="Crear lista de precio"
            @change="subirArticulos()"
            ref="file"
            severity="success"
          />
        </div>
        <div class="w-full mt-2 flex justify-between">
          <Button severity="secondary" @click="cerrarModalListaPrecio" label="Cancelar" />
          <Button severity="success" @click="crearListaPrecio" label="Crear listado" />
        </div>
      </div>
    </Dialog>
    <Dialog v-model:visible="displayAgregarMedicamento" :closable="false" modal header="Agregar medicamento" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <template #header>
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-medium white-space-nowrap">Agregar medicamento a la lista de precio <span class="font-bold">{{ listaEditar.Nombre }}</span></span>
        </div>
      </template>
      <div class="w-full">
        <div  class="w-full grid grid-cols-2 gap-2">
          <div>
            <label class="text-xs text-gray-600 italic" for="">Medicamento</label>
            <InputText type="text" class="w-full mt-1" v-model="dataMedicamentoAgregar.medicamento" />
          </div>
          <div>
            <label class="text-xs text-gray-600 italic w-full" for="">Precio</label>
            <InputNumber v-model="dataMedicamentoAgregar.precio" class="w-full mt-1" inputId="integeronly" />
          </div>
        </div>
        <div class="w-full flex gap-2 justify-end mt-2">
          <Button severity="secondary" @click="cerrarModalAddMdListaPrecio" label="Cancelar" />
          <Button label="Guardar" @click="agregarMedicamentoListaPrecio" />
        </div>
      </div>
    </Dialog>
    <Dialog v-model:visible="displayModalActualizarMasivo" :closable="false" modal :header="`Actualizar lista de precio ${listaSeleccionadaActualizar.Nombre}`" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <template #header>
        <p class="font-bold">{{ 'Actualizar lista de precio ' + listaSeleccionadaActualizar?.Nombre }}</p>
      </template>
      <div class="w-full">
        <div class="w-full bg-blue-300 p-2 rounded-md text-blue-600 flex justify-between font-bold" v-if="listaEnviar.nombre_archivo">
          <p>
            {{ listaEnviar.nombre_archivo }}
          </p>
          <button @click="borrarArchivo"><i class="pi pi-times"></i></button>
        </div>
        <FileUpload
          v-else
          mode="basic"
          class="w-full"
          name="demo[]"
          url="./upload.php"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :maxFileSize="1000000"
          chooseLabel="Actualizar lista de precio"
          @change="subirArticulos()"
          ref="file"
          severity="success"
        />
      </div>
      <div class="w-full flex justify-end mt-2 gap-2">
        <Button severity="success" @click="actualizarMasivo" label="Guardar" />
        <Button severity="secondary" @click="cerrarModalActualizarMasivo" label="Cancelar" />
      </div>
    </Dialog>
  </section>
</template>
<script>
  import { ref, computed, onMounted } from 'vue'
  import FileUpload from 'primevue/fileupload'
  import { useListaPrecioStore } from '../contratos/stores/lista-precio.store'
  import { useRouter, useRoute } from 'vue-router'
  import Swal from 'sweetalert2'
  import { useToast } from 'primevue/usetoast'
  import { helper } from '@/utils/helper'
  import dayjs from 'dayjs'

  export default {
    name: 'listasDePrecio',
    components: [
      FileUpload
    ],
    setup () {
      const toast = useToast()
      const editarLista = ref(false)
      const listaPrecioStore = useListaPrecioStore()
      const limit = ref(10)
      const offset = ref(0)
      const file = ref(null)
      const idListaSeleccionado = ref()
      const displayListaPrecio = ref(false)
      const displayAgregarMedicamento = ref(false)
      const limitInside = ref(10)
      const offsetInside = ref(0)
      const listaEditar = ref({})
      const displayModalActualizarMasivo = ref(false)
      const listaSeleccionadaActualizar = ref({})
      const filtersInside = ref({
        ItemCode: ''
      })
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value
        }
      })
      const paramsInside = computed(() => {
        return {
          limit: limitInside.value,
          offset: offsetInside.value
        }
      })
      const listaEnviar = ref({
        nombre: '',
        base64: '',
        nombre_archivo: ''
      })
      const dataMedicamentoAgregar = ref({
        medicamento: '',
        precio: 0
      })
      const expandedRows = ref([])
      const router = useRouter()
      const route = useRoute()
      const obtenerListasPrecio = () => {
        listaPrecioStore.paginar(params.value)
      }
      const onPage = ({ rows, first }) => {
        limit.value = rows
        offset.value = first
        obtenerListasPrecio()
      }
      const onPageInside = ({ rows, first }) => {
        limitInside.value = rows
        offsetInside.value = first
        paginarInterno(idListaSeleccionado.value)
      }
      const subirArticulos = () => {
        const archivo = file.value.files[0]
        listaEnviar.value.nombre_archivo = archivo.name
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            listaEnviar.value.base64 = await helper.base64String(archivo)
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const paginarInterno = (idLista) => {
        const object = {}
        for (const [key, value] of Object.entries(filtersInside.value)) {
          if (value) {
            object[key] = value
          }
        }
        listaPrecioStore.paginarMdsPreciosPorLista({ ...paramsInside.value, ...object }, idLista)
      }
      const cerrarModalListaPrecio = () => {
        listaEnviar.value = {
          nombre: '',
          base64: ''
        }
        file.value = null
        displayListaPrecio.value = false
      }
      const borrarArchivo = () => {
        listaEnviar.value.nombre_archivo = ''
        listaEnviar.value.base64 = ''
      }
      const crearListaPrecio = () => {
        delete listaEnviar.value.nombre_archivo
        for (const [key, value] of Object.entries(listaEnviar.value)) {
          if (!value) return toast.add({ severity: 'error', summary: 'Error al crear', detail: `Todos los campos son obligatorios falta: ${key}`, life: 5000 })
        }
        listaPrecioStore.crear(listaEnviar.value).then((data) => {
          if (data) {
            Swal.fire({
              title: 'Guardado',
              text: 'Lista de precio creada con éxito',
              icon: 'success'
            }).then(() => {
              obtenerListasPrecio()
            })
            cerrarModalListaPrecio()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al crear la lista de precio'
            })
          }
        })
      }
      const editarNombreListaPrecio = (payload) => {
        payload.editar_lista = false
        if (!payload.nombre_editar) return toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre no puede quedar vacio', life: 3000 })
        payload.Nombre = payload.nombre_editar
        listaPrecioStore.editarListaPrecio({ ListPriceCL: payload?.ListPriceCL, Nombre: payload.nombre_editar }).then((data) => {
          obtenerListasPrecio()
          return toast.add({ severity: 'info', summary: 'Guardado', detail: 'Lista de precios actualizada con éxito', life: 3000 })
        })
      }
      const cancelarGuardarLista = (data) => {
        data.editar_lista = false
      }
      const abrirModalAgregarMedicamento = (data) => {
        listaEditar.value = data
        displayAgregarMedicamento.value = true
      }
      const agregarMedicamentoListaPrecio = () => {
        if (!dataMedicamentoAgregar.value.medicamento) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes ingresar un medicamento para continuar', life: 3000 })
        if (!dataMedicamentoAgregar.value.precio) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes ingresar un precio para continuar', life: 3000 })
        listaPrecioStore.agregarMedicamentoAListaPrecio({ Precio: dataMedicamentoAgregar.value.precio, ItemCode: dataMedicamentoAgregar.value.medicamento, ListPriceCL: listaEditar.value.ListPriceCL }).then((data) => {
          paginarInterno(listaEditar.value.ListPriceCL)
          toast.add({ severity: 'success', summary: 'Guardado', detail: 'Medicamento agregado con éxito', life: 3000 })
          cerrarModalAddMdListaPrecio()
        })
      }
      const cerrarModalAddMdListaPrecio = () => {
        displayAgregarMedicamento.value = false
        listaEditar.value = {}
        dataMedicamentoAgregar.value = {
          medicamento: '',
          precio: 0
        }
      }
      const actualizarMasivo = () => {
        listaPrecioStore.actualizarMedicamentosMasivo(listaSeleccionadaActualizar.value.ListPriceCL, { base64: listaEnviar.value.base64 }).then((data) => {
          if (data) {
            paginarInterno(listaSeleccionadaActualizar.value.ListPriceCL)
            cerrarModalActualizarMasivo()
            return toast.add({ severity: 'success', summary: 'Error', detail: data.message, life: 10000 })
          } else {
            throw new Error('Ocurrio un error al actualizar masivo')
          }
        })
      }
      const cerrarModalActualizarMasivo = () => {
        listaEnviar.value = {
          nombre: '',
          base64: ''
        }
        file.value = null
        displayModalActualizarMasivo.value = false
        listaSeleccionadaActualizar.value = {}
      }
      /**
       * @param {object} evento
       * @param {object} evento.data
       * @param {number} evento.data.ListPriceCL
       * @param {string} evento.data.Nombre
       * @returns {Promise<void>}
       */
      const onRowGroupExpand = async (evento) => {
        listaPrecioStore.paginado_mds_precios_lista_precio = {}
        idListaSeleccionado.value = evento.data.ListPriceCL
        filtersInside.value = {
          ItemCode: ''
        }
        await router.replace({
          query: {
            ...route.query,
            ListPriceCL: evento.data.ListPriceCL
          }
        }).then(() => {
          const { query } = route
          const ListPriceCL = query.ListPriceCL
          if (ListPriceCL) {
            idListaSeleccionado.value = +ListPriceCL
            listaPrecioStore.paginarMdsPreciosPorLista(paramsInside.value, ListPriceCL)
            paginarInterno(+ListPriceCL)
            expandedRows.value = [{
              ListPriceCL: +ListPriceCL
            }]
          } else {
            expandedRows.value = []
          }
        })
      }
      const descargarListaPrecio = (data) => {
        listaPrecioStore.descargarListaPrecio(data.ListPriceCL)
      }
      const actualizarPrecio = (linea) => {
        listaPrecioStore.actualizarPrecioUnitario(linea.Id, { Precio: linea.precio_editar }).then(() => {
          linea.editar = false
          toast.add({ severity: 'success', summary: 'Guardado', detail: `Precio del medicamento ${linea.ItemCode} actualizado con éxito`, life: 8000 })
          paginarInterno(linea.ListPriceCL)
        })
      }
      onMounted(async () => {
        expandedRows.value = []
        listaPrecioStore.paginado_mds_precios_lista_precio = {}
        obtenerListasPrecio()
        if (route.query.crear) {
          displayListaPrecio.value = true
        }
      })
      return {
        expandedRows,
        listaPrecioStore,
        limit,
        offset,
        onPage,
        subirArticulos,
        file,
        paginarInterno,
        onRowGroupExpand,
        onPageInside,
        displayListaPrecio,
        cerrarModalListaPrecio,
        listaEnviar,
        crearListaPrecio,
        borrarArchivo,
        editarLista,
        editarNombreListaPrecio,
        cancelarGuardarLista,
        displayAgregarMedicamento,
        abrirModalAgregarMedicamento,
        listaEditar,
        dataMedicamentoAgregar,
        agregarMedicamentoListaPrecio,
        cerrarModalAddMdListaPrecio,
        filtersInside,
        descargarListaPrecio,
        actualizarMasivo,
        displayModalActualizarMasivo,
        listaSeleccionadaActualizar,
        cerrarModalActualizarMasivo,
        actualizarPrecio,
        dayjs
      }
    }
  }
</script>
<style scoped>
</style>
