import { defineStore } from 'pinia'
import ListaPrecioService from '../services/lista-precio.service'
// import { useContratosStore } from './contratos.store'
const _ListaPrecioService = new ListaPrecioService()
// const contratosStore = useContratosStore()

export const useListaPrecioStore = defineStore('listaPrecioStore', {
  state: () => ({
    lista_precio: [],
    listas_precio: [],
    paginado_lista_precio: {},
    paginado_mds_precios_lista_precio: {},
    listas_precio_buscadas: []
  }),
  getters: {
    _lista_precio: state => state.lista_precio,
    _listas_precio: state => state.listas_precio,
    _paginado_lista_precio: state => state.paginado_lista_precio,
    _paginado_mds_precios_lista_precio: state => state.paginado_mds_precios_lista_precio,
    _listas_precio_buscadas: state => state.listas_precio_buscadas
  },
  actions: {
    descargarPlantillaListaPrecio () {
      _ListaPrecioService.descargarPlantillaListaPrecio()
    },
    obtenerListaPrecio (params, idContrato) {
      _ListaPrecioService.obtenerListaPorContrato(params, idContrato).then(({ data }) => {
        this.lista_precio = data
      })
    },
    listarListasPrecio () {
      this.listas_precio = [
        {
          name: 'Lista de precio prueba 1',
          id: 1
        },
        {
          name: 'Lista de precio prueba 2',
          id: 2
        }
      ]
    },
    paginar (params) {
      return _ListaPrecioService.paginar(params).then(({ data }) => {
        this.paginado_lista_precio = data
        if (data.rows.length) {
          const array = data.rows.map(a => {
            return {
              ...a,
              nombre_editar: a.Nombre,
              editar_lista: false
            }
          })
          this.paginado_lista_precio.rows = array
        }
        return data
      })
    },
    paginarMdsPreciosPorLista (params, id) {
      return _ListaPrecioService.paginarMdsPreciosPorLista(params, id).then(({ data }) => {
        this.paginado_mds_precios_lista_precio.count = data.count
        this.paginado_mds_precios_lista_precio.rows = data.rows.map(a => {
          return {
            ...a,
            editar: false,
            precio_editar: +a.Price
          }
        })
        return data
      })
    },
    buscarListaPrecio (name) {
      return _ListaPrecioService.searchLista(name).then(({ data }) => {
        this.listas_precio_buscadas = data
      })
    },
    crear (payload) {
      return _ListaPrecioService.crearListaPrecio(payload).then(({ data }) => {
        return data
      })
    },
    editarListaPrecio (payload) {
      return _ListaPrecioService.editarListaPrecio(payload).then(({ data }) => {
        return data
      })
    },
    agregarMedicamentoAListaPrecio (payload) {
      return _ListaPrecioService.agregarMedicamentoAListaPrecio(payload).then(({ data }) => {
        return data
      })
    },
    descargarListaPrecio (id) {
      return _ListaPrecioService.descargarListaPrecio(id)
    },
    actualizarMedicamentosMasivo (id, payload) {
      return _ListaPrecioService.actualizarMedicamentosMasivo(id, payload).then(({ data }) => {
        return data
      })
    },
    actualizarPrecioUnitario (id, payload) {
      return _ListaPrecioService.actualizarPrecioUnitario(id, payload).then(({ data }) => {
        return data
      })
    }
  }
})
